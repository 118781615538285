.search-filter {
  background-color: $white;
}
.offcanvas {
  &__filter {
    &.offcanvas-end {
      width: 450px;
      max-width: 90%;
    }
  }
}
.tag-item {
  border: 1px solid $gray-400;
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  &--active {
    border-color: $primary;
    span {
      color: $primary;
    }
  }
}
