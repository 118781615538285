$body-bg: #E6E6E6;
$primary: #00C5F0;
$primary-light: #eaf8fb;
$light: #F2F2F2;
$warning: #E78A00;
$info: #158FFF;
$orange: #E78A00;
$yellow: #FFD746;
$info: #009DCF;
$pink: #FA9DFE;
$white: #FFFFFF;
$accordion-bg: $white;
$form-check-input-bg: $white;
$input-bg: $white;
$badge-font-weight: 400;
$font-family-base: 'Kanit';
$btn-disabled-bg: $light;
//$offcanvas-horizontal-width: 450px;

@font-face {
    font-family: "Kanit";
    src: url("./assets/fonts/Kanit-Regular.ttf");
}
@font-face {
    font-family: "Kanit";
    src: url("./assets/fonts/Kanit-Bold.ttf");
    font-weight: bold;
}

@mixin aspect($width: 16, $height: 9) {
    aspect-ratio: $width / $height;

    @supports not (aspect-ratio: $width / $height) {
        &::before {
            content: '';
            float: left;
            padding-top: calc((#{$height} / #{$width}) * 100%);
        }

        &::after {
            content: '';
            display: block;
            clear: both;
        }
    }
}

@import "~bootstrap/scss/bootstrap";

@import "./styles/vendors/swiper";
@import "./styles/vendors/bootstrap";
@import "./styles/vendors/react-calendar";
@import "./styles/vendors/google-maps-react";
@import "./styles/vendors/react-chat-elements";

@import "./styles/_common";
@import "./styles/_header";
@import "./styles/_footer";
@import "./styles/_components";
@import "./styles/_layout";
@import "./styles/accommodation";
@import "./styles/home";
@import "./styles/login";
@import "./styles/register";
@import "./styles/credit-purchase";
@import "./styles/feed";
@import "./styles/booking";
@import "./styles/create-accommodation";
@import "./styles/coupon";
