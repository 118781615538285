.feed {
  &__tab {
      background-color: $white;
      display: flex;
      justify-content: center;
  }
  &__item {
    background-color: $white;
  }
  &__profile {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../assets/images/avatar.png');
  }
  &__lessor-detail {

  }
  &__check-time {
    border: 1px $gray-200 solid;
  }
  &__people-number {
    width: 31%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $gray-100;
    height: 50px;
  }
  &__bg {
    background: $gray-100;
  }
}
