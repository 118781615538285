.react-calendar {
  font-family: inherit!important;
  width: auto;
  border: 0;
  &__viewContainer {
    border: 1px solid $gray-300;
    margin-top: 0.5rem;
    border-radius: 8px;
  }
  &__navigation {
    margin-bottom: 0;
    &__label, &__arrow {
      color: $body-color;
    }
    button {
      &.react-calendar__navigation__arrow {
        background-color: $gray-100;
        border-radius: 5px;
      }
    }
    &__prev2-button, &__next2-button {
      display: none;
    }
  }
  &__month-view{
    &__days__day {
      &--weekend {
        color: $body-color;
      }
    }

   &__weekdays{
     background: rgba(76,210,232,.12);
     padding: map-get($spacers, 3) 0;
     @include media-breakpoint-down(sm) {
       padding: map-get($spacers, 2) 0;
     }
      abbr {
        text-decoration: none;
        color: $info;
      }
   }
 }
 &__tile {
   position: relative;
   border-right: 1px solid $gray-300!important;
   border-bottom: 1px solid $gray-300!important;
   &:nth-child(7n) {
     border-right: 0!important;
   }
   &:nth-child(n+36) {
     border-bottom: 0!important;
   }
   @include aspect(1.2,1);
   color: $body-color;
   &--active {
     color: $white;
     background-color: $primary;
   }
   &--now {
     background: none;
     &:enabled:focus,&:enabled:hover {
       background: none;
     }
   }
   &--rangeStart, &--rangeEnd {
     background-color: $info!important;
   }
 }
}
