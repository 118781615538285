.credit-member-card {
  &__container {
    width: 400px;
    max-width: 100%;
    position: relative;
    background-color: $info;
    padding: $spacer;
    border-radius: 20px;
    &::after {
      display: block;
      content: '';
      background: url('../assets/images/credit-tree.png') center center / cover no-repeat;
      width: 140px;
      height: 112px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
    }
  }
  &__content {
    background-color: $white;
    padding: $spacer;
    border-radius: 20px;
    position: relative;
    z-index: 1;
  }
  &__logo {
    width: 50px;
  }
  &__duck {
    width: 40px;
  }
}

.credit-list {
  &__item {
    border-radius: $border-radius-lg;
    margin-bottom: map-get($spacers, 4);
  }
  &__duck {
    width: 30px;
  }
}
