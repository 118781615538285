.booking {
  &__header {
    border-bottom: 1px solid $gray-400;
    margin-left:-$grid-gutter-width*0.5;
    margin-right:-$grid-gutter-width*0.5;
    padding: map-get($spacers, 2) $grid-gutter-width*0.5;
  }
  &__body {
    margin-left:-$grid-gutter-width*0.5;
    margin-right:-$grid-gutter-width*0.5;
    &--border-right {
      border-right: 1px solid $gray-400;
    }
  }
  &__image {
    width: 130px;
    height: 140px;
  }
}
