.footer {
  &__content {
    background-color: $primary;
  }
  &__copyright {
    background-color: #0387b1;
  }
  &__quick-link {
    margin-bottom: 10px;
  }
  &__social-item {
    width: 22%;
    background-color: $white;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
  }
}
