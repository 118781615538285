.container-with-sidemenu {
  border-radius: 10px;
  background-color: $white;
  display: flex;
  &__menu {
    @include media-breakpoint-down(md) {
      display: none;
    }
    border-right: 2px solid $gray-300;
    padding-top: map-get($spacers, 4);
    padding-bottom: map-get($spacers, 4);
    flex-shrink: 0;
    .nav-link {
      padding-right: $spacer*4;
      padding-left: $spacer;
      color: $black;
      &.active {
        color: $white;
        background: $primary;
      }
    }
  }
  &__body {
    padding: $spacer;
    flex-grow: 1;
  }
}
.card-item {
    border-radius: 6px;
    border-top: 1px solid $gray-300;
    border-left: 1px solid $gray-300;
    box-shadow: 3px 3px 3px 0px $gray-400;
    background-color: $white;
}
