.btn-info, .btn-primary {
  color: $white!important;
}
.btn:disabled, .btn.disabled, fieldset:disabled {
  background-color: $gray-400;
  border-color: $gray-400;
}
.offcanvas-body {
  .form-check {
    label {
      font-size: $font-size-sm;
    }
  }
}
.form-control {
  appearance: auto;
}
