.room-container {
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-down(lg) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.room-item {
  width: 50%;
  margin-bottom: 1rem;
}
