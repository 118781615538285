.accommodation {
  &__more-image-items {
    width: 48%;
    margin-right: 2%;
    margin-bottom: 2%;
  }
  &__card {
    border-radius: $border-radius-lg;
    background-color: $white;
    padding: $spacer;
    &--with-header {
      position: relative;
      padding-top: 60px;
    }
  }
  &__info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: map-get($spacers, 2);
    &--summarized {
      margin-bottom: map-get($spacers, 3);
    }
  }
  &__floating-header {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $info;
    padding: map-get($spacers, 2) $spacer;
    color: $white;
    border-bottom-right-radius: 20px;
    &--center {
      text-align: center;
      border-bottom-right-radius: 0px;
      border-top-right-radius: $border-radius-lg;
      border-top-left-radius: $border-radius-lg;
      width: 100%;
    }
  }
}

.accommodation-item {
  width: 100%;
  margin-top: $spacer;
  &__image-container {
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;
    overflow: hidden;
  }
  &__image {
    width: 100%;
    @include aspect(1.35,1);
    &--standalone {
      border-radius: $border-radius-lg;
    }
  }
  &__body {
    padding: $spacer map-get($spacers, 2);
    height: 230px;
    background-color: $white;
    box-shadow: 5px 5px 5px 0px $gray-400;
    border-bottom-left-radius: $border-radius-lg;
    border-bottom-right-radius: $border-radius-lg;
    &--standalone {
      border-top-left-radius: $border-radius-lg;
      border-top-right-radius: $border-radius-lg;
    }
  }
  &__stats {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: $font-size-sm * 0.8;
    margin: map-get($spacers, 2) 0;
    &:nth-child(2) {
        border-left: 1px solid $gray-300;
        border-right: 1px solid $gray-300;
    }
    i, svg {
      font-size: 0.85em;
      color: $gray-500;
      margin-right: map-get($spacers, 1);
    }

    @include media-breakpoint-down(sm) {
      font-size: $font-size-sm;
      i, svg {
        margin-right: $spacer;
      }
    }
  }
  &__price {
    padding: map-get($spacers, 2)  0;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;
  }
  &__duck {
    width: 25px;
  }
}
.accommodation-horizontal-item {
   &__container {
     display: flex;
   }
   &__cover {
     width: 180px;
     @include media-breakpoint-down(sm) {
       width: 120px;
     }
     position: relative;
     img {
       width: 100%;
     }
   }
}
.manage-accommodation {
  &__tool-container {
    flex: 1;
    background: $gray-200;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    height: 100px;
    margin-right: 0.5rem;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }
}

.coin {
  color: $orange;
}
.star {
  color: $yellow;
}
