hr {
  color: $gray-400;
  &.dark {
    color: $gray-600;
  }
}

.image-cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.image-contain {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row-vcenter {
  display: flex;
  align-items: center;
}
.row-edge-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.bg-disabled {
    background-color: $gray-200;
}
.link {
  text-decoration: underline;
  cursor: pointer;
}
.link-white {
  text-decoration: none;
  color: $white;
  cursor: pointer;
}
.text-blue {
  color: $blue;
}
.text-orange {
  color: $orange;
}

.bg-yellow {
  background-color: $yellow;
}
.bg-primary-light {
  background-color: #dbf6fb;
}
.border-yellow {
  border-color: $yellow;
}
.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.shadow {
  box-shadow: 5px 5px 5px 0px $gray-400!important;
  background-color: $white;
}
.less-shadow {
  box-shadow: 0px 5px 20px 0px $gray-400!important;
  background-color: $white;
}
.blur-shadow {
  box-shadow: 0px 0px 30px 5px $gray-200!important;
  background-color: $white;
}
.blur-less-shadow {
  box-shadow: 0px 0px 15px 5px $gray-200!important;
  background-color: $white;
}
.absolute-fill {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.text-pink {
  color: $pink;
}
.text-yellow {
  color: $yellow;
}
.bg-primary-light {
  background-color: $primary-light;
}
