html#instant-scroll {
  scroll-behavior: auto!important;
}

#root {
   display: flex;
   min-height: 100vh;
   flex-direction: column;
}
main {
   flex: 1;
}
