.header {
  background: rgb(2,163,199);
  background: linear-gradient(90deg, rgba(2,163,199,1) 0%, rgba(0,197,240,1) 50%, rgba(80,210,231,1) 100%);
  &__logo-white {
    width: 80px;
  }
  &__text {
    font-style: italic;
    color: $white;
  }
  .dropdown-menu {
    min-width: 250px;
  }
}

.menu {
  &__icon-container {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__right {
     a {
       color: white;
       text-decoration: none;
       padding: 0 10px;
       border-right: 2px solid white;
       &:last-child {
         border: none;
       }
     }
  }
  &__profile {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../assets/images/avatar.png');
    &-online {
      position: absolute;
      bottom: 0;
      right: 0;
      color: #56CA00;
      background: $white;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      &::before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background: #56CA00;
        border-radius: 50%;
      }
    }
    &__credit {
       padding: map-get($spacers, 2);
       margin-left: -$dropdown-header-padding-x;
       margin-right: -$dropdown-header-padding-x;
       background-color: $gray-100;
    }
    &__duck {
      width: 20px;
      height: 20px;
    }
  }
}
