.coupon {
  &__card {
    overflow: hidden;
  }
  &__logo-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items:center;
  }
  &__shape {
    position: absolute;
    right: 0;
    bottom: -150px;
    width: 170px;
    height: 170px;
    border-radius: 50%;
  }
}
