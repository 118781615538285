.counter {
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $primary;
    border-radius: $border-radius-lg;
    width: 35px;
    height: 35px;
    &--disabled {
      border-color: $gray-500;
    }
  }
  &__input {
    width: 100px;

    &--sm {
      width: 65px;
    }
  }
}
.uploader {
  display: flex;
  flex-wrap: wrap;
  &__input {
    position: relative;
    width: 100px;
    height: 120px;
    border: 1px solid $gray-300;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  &__item {
    width: 100px;
    height: 120px;
    border: 1px solid $gray-300;
    border-radius: 5px;
  }
}

.input {
  &-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    padding: 0px 0.75rem;
    cursor: pointer;
  }
}
