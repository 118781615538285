.register {
  padding-left: 0;
  padding-right: 0;
  &__logo {
    width: 100px;
  }
  &__selector {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: $border-radius-lg;
    img {
      width: 80px;
      margin-right: map-get($spacers, 2);
    }
    padding: $spacer;
    margin-bottom: $spacer;
    margin-right: $spacer;
    margin-left: $spacer;
  }
}
